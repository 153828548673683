// Import CSS
import '../scss/styles.scss' 

// Import Vendor JS
import AOS from 'aos';
import mdb from 'mdb-ui-kit';

// Animations
AOS.init({
  anchorPlacement: 'top-left',
  duration: 1000
});

// Import Custom JS here
